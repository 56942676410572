<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>All Products</v-tab>
            <v-tab>Add Product</v-tab>
            <v-tab>All Specification</v-tab>
            <v-tab>Create Chalan</v-tab>
            <v-tab>All Chalan</v-tab>
            <!-- This Tab is for Product details table -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="products"
                        :search="search"
                        class="elevation-1"
                        :server-items-length="totalItems1"
                        hide-default-footer
                        style="background: #f5f5f5"
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        show-expand
                        :loading="loading"
                        loading-text="Please wait..."
                      >
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            <b>Product Id: </b> {{ item.id }} <br />
                            <b>Category : </b> {{ item.category }}<br />
                            <b>Sub category: </b> {{ item.sub_category }}<br />
                            <b>Sub Sub category : </b> {{ item.sub_sub_category
                            }}<br />
                            <b>Product Title: </b> {{ item.title }}<br />
                            <b>Product Brand: </b> {{ item.brand }}<br />
                            <b>Seller: </b> {{ item.seller_name }}<br />
                            <!-- <b>Publicly Shareable: </b> {{ item.properties -->
                            <!-- }}<br /> -->
                            <b>Origin: </b> {{ item.origin }}<br />
                            <b>Shipping Country: </b> {{ item.shipping_country
                            }}<br />
                          </td>
                        </template>
                        <template v-slot:top>
                          <v-toolbar
                            elevation="2"
                            style="background: #f5f5f5"
                            class="mt-2"
                          >
                            <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2 ml-2"
                            >
                              <v-select
                                v-model="select"
                                :items="items"
                                item-text="state"
                                @change="productselect(select)"
                              ></v-select>
                            </v-toolbar-title>

                            <v-spacer></v-spacer>
                            <!-- <div>
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div> -->

                            <div>
                              <v-btn text @click="print(select)" class="ml-10">
                                <v-icon large color="black"
                                  >mdi-printer
                                </v-icon>
                              </v-btn>
                            </div>
                            <div class="pr-4 pb-2 mt-5 pt-2 d-flex ">
                              <div class="search g">
                                  <v-text-field v-model="search_key" label="Search by id,title" filled rounded   dense hide-details>
                                  </v-text-field>
                              </div>
                              <div class="icon-search pr-4 pb-5 pt-2">
                                  <v-btn icon style="background: red"><v-icon      @click="searchHandle1">mdi-magnify</v-icon>
                                  </v-btn>                                                          
                              </div>
                              
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>

                            <!-- EDIT A PRODUCT -->
                            <v-dialog
                              :close-on-content-click="false"
                              scrollable
                              transition="scale-transition"
                              origin="top right"
                              :nudge-left="500"
                              max-width="800px"
                              max-height="650"
                              persistent
                              v-model="editdialog"
                            >
                              <v-card>
                                <v-card-title
                                  justify="center"
                                  class="
                                    d-flex
                                    flex-row
                                    justify-space-between
                                    align-center
                                  "
                                >
                                  <p class="font-weight-bold" Roboto>
                                    Edit Product
                                  </p>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="red"
                                    class="mb-2"
                                    @click="editdialog = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-title>

                                <v-card-text>
                                  <v-form
                                    ref="productEditForm"
                                    v-model="editProductForm"
                                    style="background: #f5f5f5"
                                  >
                                    <v-row class="pt-7 px-10">
                                      <v-row>
                                        <v-col class="d-flex flex-row">
                                          <p class="font-weight-medium">
                                            Category :
                                            <span class="font-weight-regular">{{
                                              editedItem.category
                                            }}</span>
                                          </p>
                                        </v-col>
                                        <v-col class="d-flex flex-row">
                                          <p class="font-weight-medium">
                                            Sub Category :
                                            <span class="font-weight-regular">{{
                                              editedItem.sub_category
                                            }}</span>
                                          </p>
                                        </v-col>
                                        <v-col class="d-flex flex-row">
                                          <p class="font-weight-medium">
                                            Sub Sub Category :
                                            <span class="font-weight-regular">{{
                                              editedItem.sub_sub_category
                                            }}</span>
                                          </p>
                                        </v-col>
                                      </v-row>

                                      <v-row>
                                        <v-col>
                                          <v-text-field
                                            v-model="editedItem.title"
                                            label="product Name"
                                            outlined
                                            dense
                                            required
                                            :rules="[
                                              (v) =>
                                                !!v ||
                                                'Product Name is required',
                                            ]"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col>
                                          <v-select
                                            v-model="editedItem.brand"
                                            :items="allBrands"
                                            item-text="Brand_name"
                                            label="Product Brand"
                                            outlined
                                            dense
                                          ></v-select>
                                        </v-col>
                                        <v-col> </v-col>
                                      </v-row>

                                      <v-row>
                                        <v-col>
                                          <v-select
                                            v-model="editedItem.origin"
                                            label="Origin Country"
                                            outlined
                                            dense
                                            :items="manufactureCountries"
                                            :rules="[
                                              (v) =>
                                                !!v ||
                                                'Origin country is required',
                                            ]"
                                          ></v-select>
                                        </v-col>
                                        <v-col>
                                          <v-select
                                            v-model="
                                              editedItem.shipping_country
                                            "
                                            label="Shipping From"
                                            :items="manufactureCountries"
                                            outlined
                                            dense
                                            :rules="[
                                              (v) =>
                                                !!v ||
                                                'Shipping country is required',
                                            ]"
                                          ></v-select>
                                        </v-col>
                                        <v-col> </v-col>
                                      </v-row>
                                    </v-row>
                                    <v-row class="px-8">
                                      <v-col cols="12">
                                        <v-textarea
                                          outlined
                                          name="input-7-4"
                                          dense
                                          label="Product Description"
                                          v-model="editedItem.description"
                                          :rules="[
                                            (v) =>
                                              !!v || 'Description is required',
                                          ]"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>
                                    <v-row class="px-10">
                                      <v-col cols="12" sm="6" md="6">
                                        <div
                                          class="d-flex flex-row"
                                          v-for="(
                                            feature, index
                                          ) in editedItem.key_features"
                                          :key="index"
                                        >
                                          <v-text-field
                                            v-model="
                                              editedItem.key_features[index]
                                            "
                                            label="Product key features"
                                            outlined
                                            dense
                                          ></v-text-field>

                                          <v-btn
                                            icon
                                            color="red"
                                            @click="
                                              deleteFeaturesForEditProduct(
                                                index
                                              )
                                            "
                                            ><v-icon>mdi-close</v-icon></v-btn
                                          >
                                        </div>
                                        <v-btn
                                          @click="addFeaturesForEditProduct"
                                          color="#854FFF"
                                          >Add Feature</v-btn
                                        >
                                      </v-col>
                                    </v-row>
                                    <div>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          color="red darken-1"
                                          @click="editdialog = false"
                                          elevation="0"
                                        >
                                          Cancel
                                        </v-btn>
                                        <v-btn
                                          color="blue"
                                          @click="saveEditProduct('')"
                                          elevation="0"
                                          :disabled="!editProductForm"
                                          
                                        >
                                          Save without editing image
                                        </v-btn>
                                        <!-- <v-btn
                                          color="green"
                                          target="_blank"
                                          @click="saveEditProduct('image')"
                                          elevation="0"
                                          :disabled="!editProductForm"
                                          
                                        >
                                          Save and Edit Image
                                        </v-btn> -->
                                      <template >
                                          <v-btn
                                          color="green"
                                        
                                          @click="saveEditProduct('image')"
                                          elevation="0"
                                          :disabled="!editProductForm"
                                        >
                                          <!-- <v-icon class="mr-2" dark> mdi-link</v-icon> -->
                                          Save and Edit Image
                                        </v-btn>
                                      </template>
                                      </v-card-actions>
                                    </div>
                                  </v-form>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                          </v-icon>
                          <v-icon small @click="deleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                        <template v-slot:item.specification="{ item }">
                          <v-btn
                            shaped
                            text
                            @click="viewSpecification(item)"
                            color="green"
                          >
                            <v-icon dark> mdi-plus</v-icon>
                            Specification
                          </v-btn>
                        </template>
                        <template v-slot:item.review="{ item }">
                          <v-btn
                            shaped
                            text
                            @click="viewReview(item)"
                            color="blue lighten-2"
                          >
                            <v-icon dark class="pr-2"> mdi-eye-outline</v-icon>
                            Review
                          </v-btn>
                        </template>
                        <template v-slot:item.comments="{ item }">
                          <v-btn
                            shaped
                            text
                            @click="viewComments(item)"
                            color="blue lighten-2"
                          >
                            <v-icon dark class="pr-2"> mdi-eye-outline</v-icon>
                            Questions
                          </v-btn>
                        </template>
                        <template v-slot:item.link="{ item }">
                          <v-btn
                            shaped
                            text
                            @click="viewLink(item)"
                            color="#FF7743"
                          >
                            <v-icon class="mr-2" dark> mdi-link</v-icon>
                            Link
                          </v-btn>
                        </template>
                        <template v-slot:item.status="{ item }">
                          <v-btn
                            text
                            small
                            ripple
                            color="red"
                            v-if="item.product_status == 'Published'"
                            @click="action(item)"
                          >
                            Unpublish</v-btn
                          >
                          <v-btn
                            text
                            small
                            ripple
                            color="primary"
                            v-else
                            @click="action(item)"
                          >
                            Publish</v-btn
                          >
                        </template>
                      </v-data-table>
                      <div class="d-flex justify-content-center mt-5">
                        <!-- <v-col cols="3" md="3"> -->
                        <div class="col-4">
                            Total {{ totalItems1 }} records
                        </div>
                        <!-- </v-col> -->
                        <!-- <v-col cols="3" md="3"> -->
                        <div class="col-4">
                            <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                            </v-select>
                            <!-- <span>Show</span>
                             @change="handlePageSizeChange($event)" -->
                            <!-- <select v-model="perPage" @change="handlePageSizeChange($event)">
                                <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
                            </select> --> 

                        </div>
                        <!-- </v-col> -->
                        <!-- <v-col cols="6" md="3" > -->
                        <div class="col-4">
                            <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                            </v-pagination>
                        </div>

                        <!-- </v-col> -->
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- This tab is for upload a product -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-card elevation="0" class="mt-1">
                    <v-card-title
                      justify="center"
                      class="d-flex flex-row justify-space-between align-center"
                    >
                      <p class="font-weight-bold" Roboto>Add New Product</p>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        ref="productForm"
                        v-model="validForm"
                        style="background: #f5f5f5"
                      >
                        <v-row class="pt-7 px-10">
                          <!-- <v-col cols="12">
                            <input
                              type="radio"
                              v-model="addItem.radioGroup"
                              v-bind:value="true"
                              id="one"
                              class="mx-2"
                            />
                            <label for="one">Share with mother site</label>
                            <input
                              type="radio"
                              v-model="addItem.radioGroup"
                              v-bind:value="false"
                              id="one"
                              class="mx-2"
                            />
                            <label for="one"
                              >Don't share with mother site</label
                            >
                          </v-col> -->

                          <v-row>
                            <!-- <v-col>
                              <v-select
                                v-model="addItem.selectedCatyegory"
                                :items="category"
                                :rules="[(v) => !!v || 'category is required']"
                                required
                                label="category"
                                outlined
                                @change="onSelectCategory"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="addItem.selectedSubCatyegory"
                                :items="sub_category"
                                :rules="[(v) => !!v || 'category is required']"
                                required
                                label="Sub category"
                                outlined
                                @change="onSelectSubCategory"
                                dense
                              ></v-select>
                            </v-col> -->
                            <!-- <v-col>
                              <v-select
                                v-model="addItem.selectedSubSubCategory"
                                :items="sub_sub_category"
                                item-text="title"
                                label="Sub Sub category"
                                outlined
                                dense
                              ></v-select>
                            </v-col> -->
                          </v-row>

                          <v-row>
                            <v-col cols="3">
                              <v-select
                                v-model="addItem.selectedSubSubCategory"
                                :items="sub_sub_category"
                                item-text="title"
                                label="Sub Sub category"
                                outlined
                                dense
                              ></v-select>
                            </v-col>
                            <v-col cols="3"
                              ><v-text-field
                                v-model="addItem.title"
                                label="Product Name"
                                outlined
                                dense
                                required
                                :rules="[
                                  (v) => !!v || 'Product Title is required',
                                ]"
                              ></v-text-field
                            ></v-col>
                            <v-col>
                              <v-select
                                v-model="addItem.brand"
                                :items="allBrands"
                                item-text="Brand_name"
                                label="Product Brand"
                                outlined
                                dense
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="addItem.origin"
                                label="Manufactured In"
                                outlined
                                dense
                                :items="manufactureCountries"
                                :rules="[
                                  (v) =>
                                    !!v || 'Manufactured Country is required',
                                ]"
                              ></v-select>
                            </v-col>
                            <v-col>
                              <v-select
                                v-model="addItem.shipping"
                                label="Shipping From"
                                :items="manufactureCountries"
                                outlined
                                dense
                                :rules="[
                                  (v) => !!v || 'Shipping Country is required',
                                ]"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-row>

                        <v-row class="px-7">
                          <v-col cols="12">
                            <v-textarea
                              outlined
                              name="input-7-4"
                              dense
                              label="Product Description"
                              v-model="addItem.description"
                              :rules="[(v) => !!v || 'Description is required']"
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-row class="px-7">
                          <v-col cols="12">
                            <v-file-input
                              color="deep-purple accent-4"
                              counter
                              dense
                              label="Upload image"
                              multiple
                              placeholder="Select your images"
                              prepend-icon="mdi-camera"
                              outlined
                              show-size
                              v-model="images"
                              @change="Preview_image"
                              @click:clear="deleteAllImage"
                              :rules="[
                                (value) =>
                                  value.length != 0 || 'Image is required',
                              ]"
                              accept="image/png, image/jpeg, image/jpg"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 6"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                  >{{ text }}</v-chip
                                >

                                <span
                                  v-else-if="index === 2"
                                  class="
                                    overline
                                    grey--text
                                    text--darken-3
                                    mx-2
                                  "
                                  >+{{ images.length - 2 }} File(s)</span
                                >
                              </template>
                            </v-file-input>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-container>
                            <v-col class="d-flex flex-row flex-wrap">
                              <v-tooltip
                                top
                                v-for="(image, index) in addItem.image"
                                :key="index"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-img
                                    :src="image.image"
                                    max-height="200"
                                    max-width="200"
                                    class="mr-2 mb-4 thumbnailImage"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="cropModal(image, index)"
                                    :style="[
                                      image.excede
                                        ? { border: '4px solid red' }
                                        : { border: '4px solid green' },
                                    ]"
                                  >
                                    <v-icon
                                      color="red"
                                      class="float-right"
                                      @click.stop="deleteImage(index)"
                                      >mdi-close-thick</v-icon
                                    >
                                  </v-img>
                                </template>
                                <span v-if="image.excede">Crop your image</span>
                                <span v-else>Cropping is not required</span>
                              </v-tooltip>
                            </v-col>
                          </v-container>
                        </v-row>
                        <v-row class="px-7">
                          <v-col cols="12" sm="6" md="6">
                            <div
                              class="d-flex flex-row"
                              v-for="(feature, index) in addItem.key_features"
                              :key="index"
                            >
                              <v-text-field
                                v-model="addItem.key_features[index]"
                                label="Product key features"
                                outlined
                                dense
                              ></v-text-field>

                              <v-btn
                                icon
                                color="red"
                                @click="deleteFeatures(index)"
                                ><v-icon>mdi-close</v-icon></v-btn
                              >
                            </div>
                            <v-btn
                              @click="addFeatures"
                              color="#854FFF"
                              elevation="0"
                              >Add Feature</v-btn
                            >
                          </v-col>
                        </v-row>

                        <div>
                          <v-card-actions class="">
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red darken-1"
                              elevation="0"
                              @click="adddialog = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              elevation="0"
                              @click="save('Pending')"
                              :disabled="!validForm"
                            >
                              Upload without publish
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              elevation="0"
                              @click="save('Published')"
                              :disabled="!validForm"
                            >
                              Upload & publish
                            </v-btn>
                          </v-card-actions>
                        </div>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- this is for all specificication  -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="specificationheaders"
                        :items="SpecificationArray"
                        :search="searchspec"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :server-items-length="totalItems2"
                        hide-default-footer
                        :loading="loading"
                        loading-text="Please wait..."
                        @current-items="myItems"
                      >
                        <template v-slot:top>
                          <v-toolbar
                            elevation="2"
                            style="background: #f5f5f5"
                            class="mt-2"
                          >
                            <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2 ml-2"
                            >
                              <v-select
                                v-model="selectspec"
                                :items="itemspec"
                                item-text="statespec"
                                @change="Specificationselect(selectspec)"
                              ></v-select>
                            </v-toolbar-title>

                            <v-spacer></v-spacer>
                            <!-- <div>
                              <v-text-field
                                v-model="searchspec"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div> -->
                            <div>
                              <v-btn text @click="printSpec" class="ml-10">
                                <v-icon large color="black"
                                  >mdi-printer
                                </v-icon>
                              </v-btn>
                            </div>
                            <div class="pr-4 pb-2 mt-5 pt-2 d-flex ">
                              <div class="search g">
                                  <v-text-field v-model="search_key" label="Search by id" filled rounded   dense hide-details>
                                  </v-text-field>
                              </div>
                              <div class="icon-search pr-4 pb-5 pt-2">
                                  <v-btn icon style="background: red"><v-icon      @click="searchHandle">mdi-magnify</v-icon>
                                  </v-btn>                                                          
                              </div>
                              
                            </div>

                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.adddiscount="{ item }">
                          <v-btn
                            text
                            color="primary"
                            @click="add_discount(item)"
                            style="padding-left: 0px !important"
                          >
                            discount
                          </v-btn>
                        </template>
                        <v-spacer> </v-spacer>

                        <template
                          v-slot:item.adquantity="{ item }"
                          class="px-0"
                        >
                          <v-btn
                            text
                            @click="ADDQuantity(item)"
                            color="primary"
                            class="px-0"
                            style="
                              padding-left: 0 !important;
                              padding-right: 0 !important;
                            "
                          >
                            <!-- <v-icon small class="mr-2"> mdi-plus </v-icon>  -->
                            Quantity & Price
                          </v-btn>
                        </template>
                        <template v-slot:item.status="{ item }">
                          <v-btn
                            text
                            small
                            ripple
                            color="red"
                            v-if="item.specification_status == 'Published'"
                            @click="onSpecificationPublishUnpublish(item)"
                            style="padding-left: 0 !important"
                          >
                            Unpublish</v-btn
                          >

                          <v-btn
                            text
                            small
                            ripple
                            color="primary"
                            v-else
                            @click="onSpecificationPublishUnpublish(item)"
                            style="padding-left: 0 !important"
                          >
                            Publish</v-btn
                          >
                        </template>
                      </v-data-table>
                      <div class="d-flex justify-content-center mt-5">
                        <!-- <v-col cols="3" md="3"> -->
                        <div class="col-4">
                            Total {{ totalItems2 }} records
                        </div>
                        <!-- </v-col> -->
                        <!-- <v-col cols="3" md="3"> -->
                        <div class="col-4">
                            <v-select dense outlined hide-details v-model="perPage2" label="Items per page" @change="handlePageSizeChange1" :items="pageSizes2" >
                            </v-select>
                            <!-- <span>Show</span>
                             @change="handlePageSizeChange($event)" -->
                            <!-- <select v-model="perPage" @change="handlePageSizeChange($event)">
                                <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
                            </select> --> 

                        </div>
                        <!-- </v-col> -->
                        <!-- <v-col cols="6" md="3" > -->
                        <div class="col-4">
                            <v-pagination v-model="currentPage2" :length="totalPages2" total-visible="7" @input="handlePageChange1">
                            </v-pagination>
                        </div>

                        <!-- </v-col> -->
                    </div>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Add quantity and price for this product -->
                <v-dialog v-model="addquantitydialog" width="750px" scrollable>
                  <v-card>
                    <v-toolbar color="primary" dark flat>
                      <v-toolbar-title>
                        {{ specdataobj.product_data.title }}
                        
                        ({{ specdataobj.size }})
                        </v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red"
                        elevation="0"
                        dark
                        small
                        class="mr-2"
                        @click="addquantitydialog = false"
                      >
                        <p class="mb-0 font-weight-medium text-body-2">
                          Cancel
                        </p>
                      </v-btn>

                      <v-btn
                        color="white"
                        elevation="0"
                        @click="confirmquantity"
                        :disabled="!priceValidationForm"
                        small
                      >
                        <p
                          class="
                            green--text
                            mb-0
                            font-weight-medium
                            text-body-2
                          "
                        >
                          Confirm
                        </p>
                      </v-btn>
                    </v-toolbar>

                    <v-divider></v-divider>
                    <v-card-text class="pt-0">
                      <v-card-title class="py-2 px-0 font-weight-bold"
                        >Price Information</v-card-title
                      >

                      <v-form v-model="priceValidationForm" ref="priceForm">
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="specdataobj.product_price.purchase_price"
                              label="Purchase Price"
                              required
                              type="number"
                              :rules="[
                                (v) => !!v || 'Purchase Price is required',
                              ]"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="specdataobj.product_price.price"
                              label="Selling Price"
                              required
                              type="number"
                              :rules="[
                                (v) => !!v || 'Selling Price is required',
                              ]"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>

                      <v-card-title class="py-2 px-0 font-weight-bold"
                        >Warehouse</v-card-title
                      >
                      <v-divider></v-divider>
                      <v-simple-table fixed-header height="300px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Warehouse Name</th>
                              <th class="text-left">Previous Quantity</th>
                              <th class="text-center">Add Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in warehouse" :key="index">
                              <td>{{ item.warehouse_name }}</td>
                              <td>{{ item.previous_quantity }}</td>
                              <td class="d-flex justify-center">
                                <v-form lazy-validation>
                                  <v-text-field
                                    outlined
                                    type="number"
                                    style="max-width: 100px; height: 50px"
                                    class="mt-2"
                                    flat
                                    dense
                                    v-model="item.quantity"
                                    @input="warehouseAdd(item)"
                                  ></v-text-field>
                                </v-form>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <v-divider></v-divider>
                      <v-card-title class="py-2 px-0 font-weight-bold"
                        >Shop</v-card-title
                      >
                      <v-divider></v-divider>

                      <v-simple-table fixed-header height="300px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Shop Name</th>
                              <th class="text-left">Previous Quantity</th>
                              <th class="text-center">Add Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in shop" :key="index">
                              <td>{{ item.shop_name }}</td>
                              <td class="text-left">
                                {{ item.previous_quantity }}
                              </td>
                              <td class="d-flex justify-center">
                                <v-form>
                                  <v-text-field
                                    outlined
                                    type="number"
                                    style="max-width: 100px; height: 50px"
                                    class="mt-2"
                                    flat
                                    dense
                                    v-model="item.quantity"
                                    @input="shopAdd(item)"
                                  ></v-text-field>
                                </v-form>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <!-- ADD Discount -->
                <v-dialog v-model="addDiscountDialog" width="750px" scrollable>
                  <v-card class="my-0">
                    <v-toolbar color="primary" dark flat>
                      <v-toolbar-title
                        >Product Name:
                        {{ specdataobjdiscount.product_data.title }} 
                      </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="pb-0" style="height: 400px">
                      <v-form
                        ref="specificationForm"
                        v-model="valid_add_discount"
                      >
                        <v-row>
                          <v-col>
                            <v-select
                              dense
                              v-model="product_dist.discount_type"
                              :items="discount_types"
                              label="Discount type"
                              :rules="[(v) => !!v || 'Unit is required']"
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model.number="product_dist.discount_amount"
                              label="Discount Amount"
                              type="number"
                              outlined
                              dense
                              :disabled="product_dist.discount_type == 'none'"
                              :rules="[
                                (v) => !!v || 'Discount amount is required',
                              ]"
                            >
                              <v-icon
                                slot="append"
                                color="red"
                                v-if="product_dist.discount_type == 'amount'"
                                >mdi-currency-bdt</v-icon
                              >
                              <v-icon
                                slot="append"
                                color="red"
                                v-if="
                                  product_dist.discount_type == 'percentage'
                                "
                                >mdi-percent</v-icon
                              >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <!-- <v-col>
                            <v-menu
                              v-model="menudist"
                              :close-on-content-click="false"
                              :nudge-right="0"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="product_dist.discount_start_date"
                                  label="Discount Start Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  dense
                                  :disabled="
                                    product_dist.discount_type == 'none' ||
                                    !product_dist.discount_amount ||
                                    product_dist.discount_amount < 0 ||
                                    typeof product_dist.discount_amount !=
                                      'number'
                                  "
                                  :rules="[
                                    (v) => !!v || 'Start date is required',
                                  ]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="product_dist.discount_start_date"
                                :min="new Date().toISOString().substr(0, 10)"
                                @input="menudist = false"
                                class="ma-0"
                                no-title
                                scrollable
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col>
                            <v-menu
                              v-model="menu2dist"
                              :close-on-content-click="false"
                              :nudge-right="0"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="product_dist.discount_end_date"
                                  label="Discount End Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  dense
                                  :disabled="
                                    product_dist.discount_type == 'none' ||
                                    !product_dist.discount_amount ||
                                    product_dist.discount_amount < 0 ||
                                    typeof product_dist.discount_amount !=
                                      'number'
                                  "
                                  :rules="[
                                    (v) => !!v || 'End date is required',
                                  ]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="product_dist.discount_end_date"
                                :min="product_dist.discount_start_date"
                                @input="menu2dist = false"
                                class="ma-0"
                                no-title
                                scrollable
                              ></v-date-picker>
                            </v-menu>
                          </v-col> -->
                          <v-col cols="5" class="mt-10 mb-5">
                            <v-datetime-picker
                              v-model="product_dist.discount_start_date"
                              prev-icon="mdi-skip-previous"
                              next-icon="mdi-skip-next"
                              class="mt-0"
                              color="purple darken-2"
                              dense
                              outlined
                              label="Start Time"
                              required
                            >
                            </v-datetime-picker>
                          </v-col>
                          <v-col cols="5" class="mt-10 mb-5">
                            <v-datetime-picker
                              v-model="product_dist.discount_end_date"
                              prev-icon="mdi-skip-previous"
                              next-icon="mdi-skip-next"
                              class="mt-0"
                              color="purple darken-2"
                              dense
                              outlined
                              label="End Time"
                            >
                            </v-datetime-picker>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <!-- <v-btn color="#854FFF" elevation="0" class="ml-3"
                  >Add New</v-btn
                >       :rules="[(v) => v.length >= 4 && v.length != 0|| 'Min 4 characters']"        -->
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red"
                        @click="addDiscountDialog = false"
                        elevation="0"
                        >Cancel</v-btn
                      >

                      <v-btn
                        color="blue darken-1"
                        @click="saveDist()"
                        elevation="0"
                        :disabled="!valid_add_discount"
                      >
                        Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-container>
            </v-tab-item>
            <!-- This Tab  is for create chalan -->
            <v-tab-item class="px-10">
              <CreateChalan />
              
            </v-tab-item>
            <!-- this is for show chalan list -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="mt-10 mb-10" elevation="0">
                      <v-data-table
                        :headers="AllChalanHeader"
                        :items="chalanproducts"
                        :server-items-length="totalItemscalan"
                        hide-default-footer
                        :search="search"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        :loading="loading"
                        loading-text="Please wait..."
                      >
                        <template v-slot:top>
                          <v-toolbar
                            elevation="2"
                            style="background: #f5f5f5"
                            class="mt-2"
                          >
                            <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2 ml-2"
                            >
                              All Chalan
                            </v-toolbar-title>

                            <v-spacer></v-spacer>
                            <div>
                              <!-- <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field> -->

                              <div class="pr-4 pb-1 mt-5 pt-2 d-flex ">
                                <div class="search g">
                                    <v-text-field v-model="search_key" label="Search by id, chalan status" filled rounded   dense hide-details>
                                    </v-text-field>
                                </div>
                                <div class="icon-search pr-4 pb-5 pt-2">
                                    <v-btn icon style="background: red"><v-icon      @click="searchHandleChalan">mdi-magnify</v-icon>
                                    </v-btn>                                                          
                                </div>                               
                            </div>
                            </div>

                            <v-divider class="mx-4" inset vertical></v-divider>
                          </v-toolbar>
                        </template>

                        <template v-slot:item.viewchalan="{ item }">
                          <v-btn
                            shaped
                            text
                            @click="viewchalanAction(item)"
                            color="primary"
                          >
                            View
                          </v-btn>
                        </template>
                      </v-data-table>
                      <div class="d-flex justify-content-center mt-5">
                        <!-- <v-col cols="3" md="3"> -->
                        <div class="col-4">
                            Total {{ totalItemscalan }} records
                        </div>
                        <!-- </v-col> -->
                        <!-- <v-col cols="3" md="3"> -->
                        <div class="col-4">
                            <v-select dense outlined hide-details v-model="perPagecalan" label="Items per page" @change="handlePageSizeChangeCalan" :items="pageSizescalan" >
                            </v-select>
                            <!-- <span>Show</span>
                             @change="handlePageSizeChange($event)" -->
                            <!-- <select v-model="perPage" @change="handlePageSizeChange($event)">
                                <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
                            </select> --> 

                        </div>
                        <!-- </v-col> -->
                        <!-- <v-col cols="6" md="3" > -->
                        <div class="col-4">
                            <v-pagination v-model="currentPagecalan" :length="totalPagescalan" total-visible="7" @input="handlePageChangeCalan">
                            </v-pagination>
                        </div>

                        <!-- </v-col> -->
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showCrop" max-width="600px" class="ma-0">
      <v-card>
        <v-row class="ma-0">
          <v-col lg="12">
            <div class="cut">
                <vue-cropper
                  ref="cropper"
                  :img="cropBlob.image"
                  :outputSize="option.size"
                  :outputType="option.outputType"
                  :info="true"
                  :full="option.full"
                  :fixed="fixed"
                  :fixedNumber="fixedNumber"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :centerBox="option.centerBox"
                  @real-time="realTime"
                  :high="option.high"
                  mode="cover"
                  :max-img-size="option.max"
                ></vue-cropper>
              </div>
            <!-- </div> -->
          </v-col>
          <v-col lg="12" class="text-center" align-self="center">
            <v-row justify="center">
              <div
                class="show-preview"
                :style="{
                  width: previews.w + 'px',
                  height: previews.h + 'px',
                  overflow: 'hidden',
                  border: '1px solid green',
                  'margin-bottom': '10px',
                }"
              >
                <div :style="previews.div">
                  <img :src="previews.url" :style="previews.img" />
                </div>
              </div>
            </v-row>
            <!-- <div class="d-flex flex-row justify-space-between mr-6">
              <v-btn @click="showCrop = false" color="red" tile elevation="0"
                >Go back</v-btn
              >

              <v-btn
                @click="getResult"
                color="success"
                tile
                elevation="0"
                outlined
                >Crop & Confirm</v-btn
              >
            </div> -->
          </v-col>
        </v-row>

        <v-row justify="center" class="mx-10 pb-10">
          <v-btn @click="showCrop = false" color="red" tile elevation="0"
            >Go back</v-btn
          >

          <v-btn @click="getResult" color="success" tile elevation="0" outlined
            >Crop & Confirm</v-btn
          >
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
       <!-- <v-dialog v-model="viewchalandialog" max-width="500px" >
<v-card>
  <v-row>
    <v-col cols="8">
{{tempobj.message}}
    </v-col>
    <v-col>
      <v-btn color="primary" text @click="viewchalandialog=false">
      </v-btn>
      <v-btn color="primary" text @click="viewChalan">

      </v-btn>
    </v-col>
  </v-row>
</v-card>
    </v-dialog> -->

    <v-dialog v-model="viewchalandialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2 pb-5">
          Chalan
        </v-card-title>

        <v-card-text>
          {{ tempobj.message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="viewchalandialog = false">
            Close
          </v-btn>
          <v-btn color="primary" text @click="viewChalan"> View Chalan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import { VueCropper } from "vue-cropper";

export default {
  data: () => ({
    crap: false,
    previews: {},
    search_key:null,
    perPage1: '10',
    perPagecalan: '10',
    perPage2: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'], 
    currentPage2: 1,
    totalItems2: 0,
    totalPages2: 0,
    pageSizes2: ['10', '30', '50', '100', 'All'],
    currentPagecalan: 1,
    totalItemscalan: 0,
    totalPagescalan: 0,
    pageSizescalan: ['10', '30', '50', '100', 'All'],
    SpecificationArray: [],
    searchspec: "",
    option: {
      img: "",
      size: 0.75,
      full: true,
      outputType: "image/png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: true,
      autoCrop: true,
      centerBox: false,
      high: true,
      max: 99999,
    },
    show: true,
    fixed: true,
    fixedNumber: [3, 4],

    menu: false,
    menu2: false,
    search: "",
    adddialog: false,
    editdialog: false,
    valid: false,
    snackbar: false,
    text: "",
    color: "",
    check: true,
    validForm: false,
    editProductForm: false,
    loading: true,

    dialog1: false,
    new_category: "",

    dialog2: false,
    new_sub_category: "",

    dialog3: false,
    new_sub_sub_category: "",

    category: [],
    selectedCatyegory: "",

    sub_category: [],
    selectedSubCatyegory: "",

    sub_sub_category: [],
    selectedSubSubCategory: "",

    shares: ["Share with mother site", "Don't share with mother site"],
    select_val: ["kg", "pcs", "gm", "none"],
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Title", value: "title" },
      { text: "Category", value: "category", sortable: false },
      { text: "No.Spec", value: "specific_status", sortable: false },
      { text: "", value: "specification", sortable: false },
      { text: "", value: "status", sortable: false },
      // { text: "Selling Price", value: "old_price" },
      { text: "", value: "review", sortable: false },
      { text: "", value: "comments", sortable: false },
      { text: "", value: "link", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    specificationheaders: [
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Title", value: "product_data.title" },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "quantity", value: "quantity", sortable: false },
      { text: "", value: "adquantity", sortable: false },

      // { text: "Selling Price", value: "old_price" },
      // { text: "Purchase Price", value: "review", sortable: false },
      // { text: "Selling Price", value: "comments", sortable: false },
      { text: "Discount", value: "adddiscount", sortable: false },
      { text: "", value: "status", sortable: false },
    ],

    TransitModelHeader: [
      { text: "ID", value: "id", sortable: false },
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "specification_id", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Brand", value: "brand_name", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "quantity", value: "requested_qty", sortable: false },
      { text: "Purchase ", value: "purchase_price", sortable: false },
      { text: "Sell", value: "selling_price", sortable: false },
    ],
    ChalanHeader: [
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Brand", value: "brand_name", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "quantity", value: "credit", sortable: false },
      { text: "Purchase ", value: "purchase_price", sortable: false },
      { text: "Selling", value: "selling_price", sortable: false },
    ],
    AllChalanHeader: [
      { text: "Chalan ID", value: "id", sortable: false },
      { text: "Created By", value: "created_by", sortable: false },
      { text: "Approved By", value: "approved_by", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "Status", value: "chalan_status", sortable: false },
      { text: "Name", value: "name" },
      { text: "Location", value: "location", sortable: false },
      { text: "", value: "viewchalan", sortable: false },
    ],
    products: [],
    contact: {},
    editedIndex: -1,
    allBrands: [],

    editedItem: {},

    addItem: {
      radioGroup: false,
      selectedCatyegory: "",
      selectedSubCatyegory: "",
      selectedSubSubCategory: "",
      title: "",
      brand: "",
      description: "",
      key_features: [],
      warranty: "",
      // properties: "",
      origin: "Bangladesh",
      shipping: "Bangladesh",
      description: "",
      image: [],
      publish_st: "Published",
    },

    images: [],
    previewURLS: [],

    user: {},

    title: "",
    brand: "",
    publish_st: "Published",
    description: "",
    key_features: [],
    quantity: "",
    // properties: true,
    point: "",
    price: "",
    purchase_price: "",
    discount_type: "",
    amount: "",
    discount_end_date: "",
    point_end_date: "",
    order: "",
    shipping: "Bangladesh",
    origin: "Bangladesh",
    manufactureCountries: [
      "Bangladesh",
      "India",
      "Pakistan",
      "China",
      "Sri Lanka",
      "Malaysia",
      "Indonesia",
      "Nepal",
      "Bhutan",
      "Maldives",
      "Japan",
      "Philippines",
      "Vietnam",
      "Turkey",
      "Iran",
      "Thailand",
      "Myanmar",
      "South Korea",
      "Saudi Arabia",
      "United Arab Emirates",
      "Singapore",
      "Kuwait",
      "Qatar",
      "Brunei",
      "Bahrain",
    ],
    warranty: "",
    product_unit: "",

    includeFiles: true,
    enabled: false,

    expanded: [],
    singleExpand: false,

    cropBlob: {},
    showCrop: false,
    resultURL: "",
    discount_types: ["none", "amount", "percentage"],
    select: { state: "All Products" },
    items: [
      { state: "All Products" },
      { state: "No Specification" },
      { state: "No Price" },
    ],

    selectspec: { statespec: "All Specifications" },
    itemspec: [
      { statespec: "All Specifications" },
      { statespec: "Shared Specification" },
    ],
    addquantitydialog: false,
    shop: [],
    warehouse: [],
    priceValidationForm: false,
    specdataobj: {
      product_data: {},
      product_price: {
        price: 0,
        purchase_price: 0,
      },
    },
    searchchalan: "",
    AllWarehouseList: [],
    trnasferob: {},
    selected: [],
    Transfersavedialog: false,
    singleSelect: false,
    valid_add_discount: false,
    addDiscountDialog: false,
    specdataobjdiscount: {
      product_data: {},
    },
    product_dist: {
      discount_type: "none",
      discount_amount: 0,
      discount_start_date: "",
      discount_end_date: "",
    },

    menudist: false,
    menu2dist: false,
    addedwarehousequantity: [],
    addedshopquantity: [],
    quantityIndex: -1,
    quantityID: -1,
    allchalanArray: [],
    chalanproducts: [],
    allChalanobj: {},
    viewchalandialog: false,
    tempobj: {
      data: {},
    },
    searchitemArray: [],
  }),

  components: {
    VueCropper,
    GroupProduct: () => import(/* */ "@/components/groupProduct.vue"),
    GroupProductList: () => import(/* */ "@/components/GroupProductList.vue"),
    CreateChalan: () => import(/* */ "@/components/createChalan.vue"),
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    addquantitydialog: function (newValue) {
      this.addedwarehousequantity.length = 0;
      this.addedshopquantity.length = 0;

      // IF add quantity and price modal is closed then reset all the variable
      if (!newValue) {
        this.quantityIndex = -1;
        this.quantityID = -1;
        this.$refs.priceForm.reset();
      }
    },
  },

  methods: {
    searchHandle1(event){
      this.productselect();
    },
    searchHandle(event){
      this.Specificationselect();
    },
    searchHandleChalan(event){
      this.initialize();
    },
    handlePageSizeChange(event) {
        this.perPage1 = (event);
        this.loading = true;
        this.productselect();
    },

    handlePageChange(value) {      
      this.currentPage1 = value;
      this.loading = true;
      this.productselect();
      window.scrollTo(0, 0);
    },
    handlePageSizeChangeCalan(event) {
        this.perPagecalan = (event);
        this.loading = true;
        this.initialize();
    },

    handlePageChangeCalan(value) {
      this.currentPagecalan = value;
      this.loading = true;
      this.initialize();
      window.scrollTo(0, 0);
    },
    handlePageSizeChange1(event) {
        this.perPage2 = (event);
        this.loading = true;
        this. Specificationselect();
    },

    handlePageChange1(value) {
      this.currentPage2 = value;
      this.loading = true;
      this. Specificationselect();
      window.scrollTo(0, 0);
    },
    initialize() {
      let perPagecalan11 ;
        if (this.perPagecalan === 'All'){
            perPagecalan11 = this.totalItemscalan;
        }
        else
        {
            perPagecalan11 = this.perPagecalan;
        }
        this.loading = true;
      // axios.get("product/product_details/").then((response) => {
      //   this.products = response.data;
      //   this.loading = false;
      // });
      

      axios.get("manager/transferable_list").then((response) => {
        if (response.data.success) {
          this.AllWarehouseList = response.data.data;
          this.AllWarehouseList.forEach((element, index) => {
            element.flagvalue = element.value;
            element.value = index;
          });
        }
      });

      axios.get(`manager/show_all_chalan/?page=${this.currentPagecalan}&size=${perPagecalan11}&search_key=${this.search_key}`).then((response) => {
        // if (response.data.success) {
        //   this.chalanproducts = response.data.data;
        //   console.log("this is after ", response.data);
        // }
        if (response.status === 200) {
          this.chalanproducts = response.data.results; 
          this.totalItemscalan = response.data.count;
          this.totalPagescalan = parseInt(this.totalItemscalan / this.perPagecalan)
          this.loading = false;
        }
      });
    },

    getAllBrands() {
      axios.get("productdetails/get_brands/").then((response) => {
        this.allBrands = response.data.data;
      });
    },

    addFeatures() {
      this.addItem.key_features.push("");
    },

    deleteFeatures(index) {
      this.addItem.key_features.splice(index, 1);
    },

    addFeaturesForEditProduct() {
      this.editedItem.key_features.push("");
    },

    deleteFeaturesForEditProduct(index) {
      this.editedItem.key_features.splice(index, 1);
    },

    DiscountEndInput() {
      this.discount_end_date = this.date;

      this.menu = false;
    },

    PointEndInput() {
      this.point_end_date = this.dates;

      this.menu2 = false;
    },

    Preview_image() {
      this.images.forEach((image) => {
        if (/\.(jpg|png|jpeg)$/i.test(image.name) == false) {
          alert("We accept image file ends with jpg/jpeg/png only !");
          this.images.length = 0;
          this.addItem.image.length = 0;
          return;
        }

        let previewObject = {};

        const reader = new FileReader();
        reader.onload = (e) => {
          previewObject.image = e.target.result;
        };
        reader.readAsDataURL(image);

        let BlobObject = URL.createObjectURL(image);

        let img = new Image();
        img.src = BlobObject;

        img.onload = () => {
          let w = img.width;
          let h = img.height;

          let excedeFlag = true;
          let aspectRatio = w/h;
          if(aspectRatio >= 0.72 && aspectRatio <= 0.78){
            excedeFlag = false;
          }

          

          previewObject.excede = excedeFlag;
          previewObject.name = image.name;
          previewObject.type = image.type;
          this.addItem.image.push(previewObject);
        };

        // reader.readAsBinaryString(image);

        // reader.onload = () => {
        //   let file = btoa(reader.result);
        //   previewObject.image = file;
        //   this.addItem.image.push(previewObject);
        // };

        // console.log("BEFORE" , image)
        // let file = URL.createObjectURL(image);

        // let myFile = new File(
        //   [file],
        //   image.name,
        //   {
        //     type: image.type,
        //   }
        // );

        // console.log("AFTER" , myFile)

        // let img = new Image();
        // img.src = file;

        // img.onload = () => {
        //   let w = img.width;
        //   let h = img.height;
        //   let excedeFlag = false;

        //   if (w > 475 || h > 475) {
        //     excedeFlag = true;
        //   }

        //   this.addItem.image.push({
        //     image: file,
        //     excede: excedeFlag,
        //     name: image.name,
        //     type: image.type,
        //   });
        // };
      });
    },

    cropModal(image, index) {
      this.cropBlob = image;
      this.cropBlob.index = index;
      this.showCrop = true;
    },

    realTime(data) {
      this.previews = data;
    },

    getResult() {
      this.$refs.cropper.getCropData((data) => {
        this.addItem.image[this.cropBlob.index].image = data;
        this.addItem.image[this.cropBlob.index].excede = false;
        this.showCrop = false;

        // let img = window.URL.createObjectURL(data);
        // this.addItem.image[this.cropBlob.index].image = img;
        // this.addItem.image[this.cropBlob.index].excede = false;
        // this.showCrop = false;
      });
    },

    deleteImage(index) {
      this.addItem.image.splice(index, 1);
      this.images.splice(index, 1);
    },

    deleteAllImage() {
      this.images.length = 0;
      this.addItem.image.length = 0;
    },

    save(status) {
      //Upload a product

      var formData = new FormData();

      // formData.append("properties", this.addItem.radioGroup);
      formData.append("category", this.addItem.selectedCatyegory);
      formData.append("sub_category", this.addItem.selectedSubCatyegory);
      formData.append("sub_sub_category", this.addItem.selectedSubSubCategory);
      formData.append("title", this.addItem.title);
      formData.append("brand", this.addItem.brand);
      formData.append("description", this.addItem.description);
      formData.append("key_features", this.addItem.key_features);
      formData.append("shipping_country", this.addItem.shipping);
      formData.append("origin", this.addItem.origin);
      formData.append("count", this.addItem.image.length);
      formData.append("publish", status);
      formData.append("user_role", this.user.role);
      formData.append("user_id", this.user.user_id);

      // Image append in form data array
      for (var i = 0; i < this.addItem.image.length; i++) {
        // const file = new File(
        //   [this.addItem.image[i].image],
        //   this.addItem.image[i].name || `File_${i}`,
        //   {
        //     type: this.addItem.image[i].type,
        //   }
        // );

        // console.log("Finally ", file);

        formData.append("images[" + i + "]", this.addItem.image[i].image);
      }

      for (var pair of formData.entries()) {
        // console.log(pair[0] + ", " + pair[1]);
      }

      axios
        .post(`product/add_product/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.success) {
            this.products.unshift(response.data.product_data);
            this.text = "Product has been uploaded successfully";
            // this.text = response.data.message;
            this.color = "success";
            this.$refs.productForm.reset();
            this.addItem.key_features.length = 0;
            this.addItem.image.length = 0;
            this.addItem.radioGroup = false;
          } else {
            //this.text = "Something went wrong !!";
            this.text = response.data.message;
            this.color = "error";
          }
          this.snackbar = true;
        })
        .catch((err) => {
          //this.text = "Something went wrong !!";
          this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

   async saveEditProduct(image) {
      var formData = new FormData();
      // window.open.post(`product/modify_product/${this.editedItem.id}/`, '_blank')
      // window.open('_')
      // window.open("_blank");
      formData.append("category", this.editedItem.category);
      formData.append("sub_category", this.editedItem.sub_category);
      formData.append("sub_sub_category", this.editedItem.sub_sub_category);
      formData.append("title", this.editedItem.title);
      formData.append("brand", this.editedItem.brand);
      formData.append("description", this.editedItem.description);
      formData.append("key_features", this.editedItem.key_features);
      formData.append("shipping_country", this.editedItem.shipping_country);
      formData.append("origin", this.editedItem.origin);                                                                    
      formData.append("user_role", this.user.role);
      formData.append("user_id", this.user.user_id);

      // Object.assign(this.products[this.editedIndex], this.editedItem);
      //  for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

     await axios
        .post(`product/modify_product/${this.editedItem.id}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.success) {
            let ID = this.editedItem.id;
            Object.assign(this.products[this.editedIndex], this.editedItem);
            this.text = "Product has been edited successfully";
            this.color = "success";
            this.editedItem = {};
            this.snackbar = true;
            this.editdialog = false;

            if (image == "image") {           
              let routeData = this.$router.resolve({
                name: "Edit Product Image",
                params: { id: ID },
                query: { spm: ID },
              });

              
              // console.log('routedata = ', routeData);

              // dashboard/pages/edit-product-image?spm=600
              routeData.href = `/dashboard/pages/edit-product-image?spm=${ID}`;
              window.open(routeData.href, "_blank");
              
              
              
              
              

              // this.$router.push({
              //   name: "Edit Product Image",
              //   params: { id: ID },
              //   query: { spm: ID },
              // });
              // console.log('imageurl = ', imageurl)
              
            }
          } else {
            //this.text = "Something went wrong !!";
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          this.color = "error";
          this.snackbar = true;
        });
    },

    saveCategory() {
      this.category.push(this.new_category);
      this.dialog1 = false;
    },

    AddSubCategory() {
      this.sub_category.push(this.new_sub_category);
      this.dialog2 = false;
    },
    unpublish() {},

    addSubSubCategory() {
      this.sub_sub_category.push(this.new_sub_sub_category);
      this.dialog3 = false;
    },

    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editdialog = true;
    },

    // deleteItem(item) {
    //   if (confirm("Do you really want to delete?")) {
    //     axios.post(`product/delete_product/${item.id}/`).then((response) => {
    //       this.products = this.products.filter(
    //         (contact) => contact.id != item.id
    //       );
    //     });
    //   }
    // },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`product/hide_delete/${item.id}/`).then((response) => {
          this.products = this.products.filter(
            (contact) => contact.id != item.id
          );
        });
      }
    },

    viewSpecification(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/specification/${item.id}`,
      });
      routeData.href = `/dashboard/pages/specification/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewReview(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/review/${item.id}`,
      });
      routeData.href = `/dashboard/pages/review/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewComments(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/viewcomments/${item.id}`,
      });
      routeData.href = `/dashboard/pages/viewcomments/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewLink(item) {
      let routeData = this.$router.resolve({
        name: `https://ambor.com.bd/products/${item.title.replaceAll(
          " ",
          "-"
        )}/details/?spm=${item.id}`,
      });
      routeData.href = `https://ambor.com.bd/products/${item.title.replaceAll(
        " ",
        "-"
      )}/details/?spm=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    getAllCastegory() {
      axios.get("category/categories/").then((response) => {
        this.category = response.data;
        this.addItem.selectedCatyegory = this.category[0];
        axios
        .post(`category/subcategories/`, {
          name: this.addItem.selectedCatyegory,
        })
        .then((response) => {
          this.sub_category = response.data;
          this.addItem.selectedSubCatyegory = this.sub_category[0];
          axios
        .post(`category/subsubcategories/`, {
          name: this.addItem.selectedSubCatyegory,
        })
        .then((response) => {
          this.sub_sub_category = response.data;
        });
        });
      });
    },

    onSelectCategory() {
      axios
        .post(`category/subcategories/`, {
          name: this.addItem.selectedCatyegory,
        })
        .then((response) => {
          this.sub_category = response.data;
        });
    },

    // Product publish/unpublish
    action(item) {
      const index = this.products.indexOf(item);
      axios
        .get(`product/publish_unpublish_product/${item.id}/`)
        .then((response) => {
          this.products[index].product_status = response.data.product_status;
          window.location.reload(true);
        });
    },

    // Specification publish/unpublish
    onSpecificationPublishUnpublish(item) {
      const index = this.SpecificationArray.indexOf(item);
      axios
        .get(`product/publish_unpublish_specification/${item.id}/`)
        .then((response) => {
          this.SpecificationArray[index].specification_status =
            response.data.specification_status;
        });
    },

    // This Link will be Change for link change
    print(select) {
      if (this.select == "All Products") {
        let routeData = this.$router.resolve({
          // name: `http://68.183.231.43/site/get_pdf/`,
          name: `site/get_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_pdf/`;
        routeData.href = axios.defaults.baseURL + "site/get_pdf/";
        //  console.log(axios.defaults.baseURL+"site/get_pdf/")
        window.open(routeData.href, "_blank");
      } else if (this.select == "No Specification") {
        let routeData = this.$router.resolve({
          name: `get_nospec_pdf/`,
          //name: `http://68.183.231.43/site/get_nospec_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_nospec_pdf/`;
        routeData.href = axios.defaults.baseURL + "site/get_nospec_pdf/";
        window.open(routeData.href, "_blank");
      } else if (this.select == "No Price") {
        let routeData = this.$router.resolve({
          // name: `http://68.183.231.43/site/get_nospecPrice_pdf/`,
          name: `site/get_nospecPrice_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_nospecPrice_pdf/`;
        routeData.href = axios.defaults.baseURL + "site/get_nospecPrice_pdf/";
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          // name: `http://68.183.231.43/site/get_pdf/`,
          name: `site/get_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_pdf/`;
        routeData.href = axios.defaults.baseURL + "site/get_pdf/";
        window.open(routeData.href, "_blank");
      }

      // console.log("this is for method test" ,this.select)
      // let routeData = this.$router.resolve({
      //   name: `https://tes.com.bd/site/get_pdf/`,
      // });
      // routeData.href = `https://tes.com.bd/site/get_pdf/`;
      // window.open(routeData.href, "_blank");
    },
    onSelectSubCategory() {
      axios
        .post(`category/subsubcategories/`, {
          name: this.addItem.selectedSubCatyegory,
        })
        .then((response) => {
          this.sub_sub_category = response.data;
        });
    },

    productselect() {
      // console.log('product select method called');
      // console.log();
      if (this.select == "All Products") {
        
        let perPage ;
        if (this.perPage1 === 'All'){
            perPage = this.totalItems1;
        }
        else
        {
            perPage = this.perPage1;
        }
        this.loading = true;
        axios.get(`product/product_details/?page=${this.currentPage1}&size=${perPage}&search_key=${this.search_key}`).then((response) => {
          if (response.status === 200){
            this.products = response.data.results;
            this.totalItems1 = response.data.count;
            this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
            this.loading = false;
          }
        });
      } 
      else if (this.select == "No Specification") {
        let perPage ;
        if (this.perPage1 === 'All'){
            perPage = this.totalItems1;
        }
        else
        {
            perPage = this.perPage1;
        }
        this.loading = true;
        axios.get(`product/nospecification_products/?page=${this.currentPage1}&size=${perPage}&search_key=${this.search_key}`).then((response) => {
          if (response.status === 200){
            this.products = response.data.results;
            this.totalItems1 = response.data.count;
            this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
            this.loading = false;
          }

          // this.products = response.data.data;
          // this.loading = false;
        });
      } 
      else if (this.select == "No Price") {
        let perPage ;
        if (this.perPage1 === 'All'){
            perPage = this.totalItems1;
        }
        else
        {
            perPage = this.perPage1;
        }
        this.loading = true;
        axios.get(`product/noprice_products/?page=${this.currentPage1}&size=${perPage}&search_key=${this.search_key}`).then((response) => {
          if (response.status === 200){
            this.products = response.data.results;
            this.totalItems1 = response.data.count;
            this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
            this.loading = false;
          }

          // this.products = response.data.data;
          // this.loading = false;
        });
      } 
      else {
          let perPage ;
          if (this.perPage1 === 'All'){
              perPage = this.totalItems1;
          }
          else
          {
              perPage = this.perPage1;
          }
          this.loading = true;
        axios.get(`product/product_details/?page=${this.currentPage1}&size=${perPage}&search_key=${this.search_key}`).then((response) => {
          if (response.status === 200){
            this.products = response.data.results;
            this.totalItems1 = response.data.count;
            this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
            this.loading = false;

          }
          // console.log('response data = ', response.data.results)
          // this.products = response.data.results;
          // this.loading = false;
        });
      }
    },
     async Specificationselect() { 
      if (this.selectspec == "All Specifications") {
        let perPage1 ;
        if (this.perPage2 === 'All'){
            perPage1 = this.totalItems2;
        }
        else
        {
            perPage1 = this.perPage2;
        }
        this.loading = true;

         await axios.get(`productdetails/getallSpecification/?page=${this.currentPage2}&size=${perPage1}&search_key=${this.search_key}`).then((response) => {
          // this.SpecificationArray = response.data.data;
          // this.loading = false;
          if (response.status === 200){
            this.SpecificationArray = response.data.results;
            this.totalItems2 = response.data.count;
            this.totalPages2 = parseInt(this.totalItems2 / this.perPage2);
            this.loading = false;
          }
        });
      }
       else if (this.selectspec == "Shared Specification") { 
        let perPage1 ;
        if (this.perPage2 === 'All'){
            perPage1 = this.totalItems2;
        }
        else
        {
            perPage1 = this.perPage2;
        }
        this.loading = true;       
        await axios.get(`productdetails/all_own_shared_products/?page=${this.currentPage2}&size=${perPage1}&search_key=${this.search_key}`)
          .then((response) => {
            if (response.status === 200){
            this.SpecificationArray = response.data.results;
            this.totalItems2 = response.data.count;
            this.totalPages2 = parseInt(this.totalItems2 / this.perPage2);
            this.loading = false;
          }

            // this.SpecificationArray = response.data.data;

            // this.loading = false;
            // console.log("fjgbfg", this.SpecificationArray);
          });
      } 
      else {
        let perPage1 ;
        if (this.perPage2 === 'All'){
            perPage1 = this.totalItems2;
        }
        else
        {
            perPage1 = this.perPage2;
        }
        this.loading = true;
        await axios.get(`productdetails/getallSpecification/?page=${this.currentPage2}&size=${perPage1}&search_key=${this.search_key}`).then((response) => {
         
          
          if (response.status === 200){
            this.SpecificationArray = response.data.results;
            this.totalItems2 = response.data.count;
            this.totalPages2 = parseInt(this.totalItems2 / this.perPage2);
            this.loading = false;
          }
        });
      }
    },
    ADDQuantity(item) {
      this.quantityID = item.id;
      this.quantityIndex = this.SpecificationArray.findIndex(
        (spec) => spec.id == item.id
      );
      Object.assign(this.specdataobj, item);
      // console.log(this.specdataobj);

      axios
        .get(`productdetails/quantity_info/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data.shop;
            this.warehouse = response.data.data.warehouse;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            this.text = "Something went wrong !";
            //this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong !";
          //this.text = response.data.message;
          this.snackbar = true;
        });
    },

    // Add a new warhouse if user give quantity
    warehouseAdd(item) {
      //check array length for new item or not
      if (this.addedwarehousequantity.length > 0) {
        let index = this.addedwarehousequantity.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );

        if (index != -1) {
          this.addedwarehousequantity[index].quantity = item.quantity;
        } else {
          this.addedwarehousequantity.push(item);
        }
      } else {
        this.addedwarehousequantity.push(item);
      }
    },

    // Add a new Shop if user give quantity
    shopAdd(item) {
      if (this.addedshopquantity.length > 0) {
        let index = this.addedshopquantity.findIndex(
          (shop) => shop.shop_id == item.shop_id
        );
        if (index != -1) {
          this.addedshopquantity[index].quantity = item.quantity;
        } else {
          this.addedshopquantity.push(item);
        }
      } else {
        this.addedshopquantity.push(item);
      }
    },

    confirmquantity(specdataobj) {
      let datas = {
        product_id: this.specdataobj.product_id,
        specification_id: this.quantityID,
        warehouse: this.addedwarehousequantity,
        shop: this.addedshopquantity,
        purchase_price: Number(this.specdataobj.product_price.purchase_price),
        selling_price: Number(this.specdataobj.product_price.price),
      };

      let total = 0;

      datas.warehouse.forEach((element) => {
        total = Number(total) + Number(element.quantity);
      });

      datas.shop.forEach((element) => {
        total = Number(total) + Number(element.quantity);
      });
      // console.log("this is datasss", datas);
      // If user did not add any quantity throw error
      if (!total) {
        this.text = "Quantity can not be empty";
        this.color = "warning";
        this.snackbar = true;
      } else {
        // If quantity and price exists
        axios
          .post(
            `productdetails/insert_specification_quantity/${this.user.user_id}/`,
            datas
          )
          .then((response) => {
            if (response.data.success) {
              this.text = response.data.message;
              this.color = "success";
              this.addquantitydialog = false;
              this.snackbar = true;
            } else {
              this.text = response.data.message;
              this.color = "warning";
              this.addquantitydialog = false;
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = "Something went wrong ";
            this.color = "warning";
            this.addquantitydialog = false;
            this.snackbar = true;
          });
      }
    },
    savetransit(selected) {
      // console.log("this is ", this.selected);
      this.Transfersavedialog = true;
    },
    CancelInvoice() {
      this.Transfersavedialog = false;
    },
    add_discount(item) {
      this.addDiscountDialog = true;
      Object.assign(this.specdataobjdiscount, item);
      // console.log(this.specdataobjdiscount);
    },
    Canceldiscount() {
      this.addDiscountDialog = false;
    },
    saveDist(specdataobjdiscount) {
      let datas = {
        discount_type: this.product_dist.discount_type,
        discount_amount: this.product_dist.discount_amount,
        discount_start_date: this.product_dist.discount_start_date,
        discount_end_date: this.product_dist.discount_end_date,
        product_id: this.specdataobjdiscount.product_id,
      };
      // console.log("this is item", datas);

      axios
        .post(`site/add_discount/${this.specdataobjdiscount.id}/`, datas)
        .then((response) => {
          if (response.data.success) {
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            this.addDiscountDialog = false;
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },
    TransferSelectInfo() {
      // console.log(this.trnasferob);
      axios
        .get(
          `productdetails/get_product_list/${this.trnasferob.flagvalue}/${this.trnasferob.id}/`
        )
        .then((response) => {
          if (response.data.success) {
            this.allchalanArray = response.data.data;
          }
        });
    },

    SaveApproveInvoice(trnasferob) {
      let datas = {
        user_id: this.user.user_id,
        transaction_info: this.selected,
      };
      axios
        .post(`manager/create_chalan/${this.trnasferob.flagvalue}/`, datas)
        .then((response) => {
          if (response.data.success) {
            // this.text = response.data.message;
            this.tempobj = response.data;
            // this.color = "success";
            // this.snackbar = true;
            this.Transfersavedialog = false;
            this.allchalanArray = this.allchalanArray.filter((element) => {
              this.selected.some((item) => item.id == element.id);
            });
            this.selected = [];
            this.TransferSelectInfo();
            this.viewchalandialog = true;
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },
    viewchalanAction(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/admin_chalan/${item.id}`,
      });
      routeData.href = `/dashboard/pages/admin_chalan/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },
    viewChalan(tempobj) {
      // console.log("this is clicked", tempobj);
      this.$router.push({
        path: "/dashboard/pages/admin_chalan/",
        query: { id: this.tempobj.data.id },
      });
    },

    myItems(items) {
      // console.log("After sorted", items);
      this.searchitemArray = items;
    },
    printSpec(searchitemArray) {
      // console.log("this is search data array", this.searchitemArray);

      let href = axios.defaults.baseURL + "site/allspec_pdf";
      window.open(href, "_blank");

      // axios
      //   .get("site/search_spec_pdf/" ,this.searchitemArray)
      //   .then((response) => {

      //    let routeData = this.$router.resolve({

      //       name: `site/search_spec_pdf/`,
      //     });

      //     let href = axios.defaults.baseURL + "site/search_spec_pdf/?"+ "myarray="+;

      //     window.open(href, "_blank");
      //   });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.initialize();
    this.getAllCastegory();
    this.getAllBrands();
    this.productselect();
    this.Specificationselect();
    this.ADDQuantity();
    this.TransferSelectInfo();
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}
.cut-wrapper {
  width: 100% !important;
  height: 0% !important;
  padding-bottom: 100%;
  position: relative;
  margin: 0 auto;
}
.cut-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

